import ReactDOM from "react-dom/client"
import "./assets/styles/global.css"
import MedusaApp from "./medusa-app"
import { ExtensionsEntry } from "./types/extensions"
import "./i18n"
import { createEntry } from "./node/utils/create-entry"
import path from "path"

async function run() {


  let exts: ExtensionsEntry[] = []

    const { default: extensions } = (await import(
      // @ts-ignore - this file is generated at build time
      "./extensions/_main-entry.ts"
    )) as { default: ExtensionsEntry[] }
    exts = extensions

  const app = new MedusaApp({ entries: exts })

  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    app.render()
  )
}

run()
